<template>
  <b-datepicker
    v-model="date"
    icon="calendar-today"
    :unselectable-dates="unselectableDates"
    v-bind="$attrs"
  ></b-datepicker>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: dayjs.Dayjs,
    fetureOnly: Boolean,
  },
  computed: {
    date: {
      get() {
        return this.value ? this.value.toDate() : null
      },
      set(val) {
        this.$emit('input', val ? dayjs(val) : null)
      },
    },
  },
  methods: {
    unselectableDates(day) {
      if (this.fetureOnly) {
        return dayjs(day).isBefore(dayjs(), 'day')
      }
      return false
    },
  },
}
</script>

<style></style>
