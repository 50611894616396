/**
 * バリデーションエラー業務例外
 *
 * @export
 * @class InValidServiceException
 * @extends {Error}
 */
export class InValidServiceException extends Error {
  data: any
  constructor(data: any, message = 'InValidServiceException') {
    super(message)
    this.message = message || ''
    this.data = data
    Object.setPrototypeOf(this, InValidServiceException.prototype)
  }
}

export class ConfirmServiceException extends Error {
  constructor(message: string) {
    super(message)
    this.message = message || ''
    Object.setPrototypeOf(this, ConfirmServiceException.prototype)
  }
}

export class UnAuthoriedException extends Error {
  constructor(message = 'UnAuthoriedException') {
    super(message)
    Object.setPrototypeOf(this, UnAuthoriedException.prototype)
  }

  static plsLogin() {
    window.location.reload()
  }
}

export default {}
