function Enum<T>() {
  abstract class Resource {
    value: any
    label: string
    constructor(value: any, label: string) {
      this.value = value
      this.label = label
    }

    public static get list(): T[] {
      // @ts-ignore
      return Object.keys(this).map(key => this[key])
    }

    public static of(value: any): T {
      // @ts-ignore
      return this.list.find(t => t.value === value) || this.list[0]
    }

    private get selfClass(): T {
      return Object.getPrototypeOf(this).constructor
    }

    public is(key: string) {
      // @ts-ignore
      return this.selfClass[key] === this
    }

    public in(keys: string[]) {
      // @ts-ignore
      return keys.map(key => this.selfClass[key]).includes(this)
    }
  }
  return Resource
}
export class WeekDay extends Enum<WeekDay>() {
  static Mon = new WeekDay(1, '月')
  static Tue = new WeekDay(2, '火')
  static Wed = new WeekDay(3, '水')
  static Thu = new WeekDay(4, '木')
  static Fri = new WeekDay(5, '金')
  static Sat = new WeekDay(6, '土')
  static San = new WeekDay(7, '日')
}

export class UserRole extends Enum<UserRole>() {
  static Admin = new UserRole('admin', '管理者')
  static Stuff = new UserRole('stuff', 'スタッフ')
  static Limited = new UserRole('limited', '限定ユーザ')
}

export class AreaMaxHour extends Enum<AreaMaxHour>() {
  static SevenHalf = new AreaMaxHour('7.5', '7.5時間')
  static Eight = new AreaMaxHour('8', '8時間')
}
export class AreaPaymentLimit extends Enum<AreaPaymentLimit>() {
  static Fifteen = new AreaPaymentLimit(1, '毎月15日締切、当月25日支払')
  static MonthEnd = new AreaPaymentLimit(
    2,
    '毎月末日締切、翌月15日支払 銀行振込',
  )
  get shortLabel() {
    return this.label.split('、')[0]
  }
}
export class AreaShiftType extends Enum<AreaShiftType>() {
  static Monthly = new AreaShiftType(1, '１ヶ月単位の変形労働時間制')
  static Free = new AreaShiftType(2, 'シフトにより調整')
  static Emptu = new AreaShiftType(3, '')
}
export class AreaOptionType extends Enum<AreaOptionType>() {
  static Location = new AreaOptionType('location', '勤務地', 'is-dark')
  static Department = new AreaOptionType('department', '部署', 'is-info')
  static Occupation = new AreaOptionType('occupation', '職種', 'is-success')
  static Content1 = new AreaOptionType('content1', '業務内容1', 'is-dark')
  static Content2 = new AreaOptionType('content2', '業務内容2', 'is-dark')

  iconClass: string
  constructor(value: any, label: string, iconClass: string) {
    super(value, label)
    this.iconClass = iconClass
  }
}

export class MemberGender extends Enum<MemberGender>() {
  static Male = new MemberGender(1, '男性')
  static FeMale = new MemberGender(2, '女性')
}
export class MemberNGAction extends Enum<MemberNGAction>() {
  static Tel = new MemberNGAction(1, '電話')
  static Mail = new MemberNGAction(2, 'メール')
  static Direct = new MemberNGAction(3, '口頭')
}
export class MemberStatus extends Enum<MemberStatus>() {
  static Apply = new MemberStatus(0, '応募済み')
  static Interviewed = new MemberStatus(1, '面談済み')
  static Recruit = new MemberStatus(2, '採用フロー行き')
  static Workation = new MemberStatus(3, '通知書設定済み')
  static Flow = new MemberStatus(4, 'ワークフロー中')
  static Approval = new MemberStatus(5, '承認済み')
  static Prepare = new MemberStatus(6, '入社フォーム済')
  static Success = new MemberStatus(10, '採用完了')
  static NG = new MemberStatus(11, 'NG')
}

export class WorkationLicense extends Enum<WorkationLicense>() {
  // static A = new WorkationLicense(1, '社員（総合）')
  static Emp = new WorkationLicense(2, '社員')
  static Contract = new WorkationLicense(3, '契約職員')
  static Free = new WorkationLicense(4, 'フルタイムパート')
  static Student = new WorkationLicense(5, '学生アルバイト')
  static Part = new WorkationLicense(6, 'パート')
}

export class WorkationRenewalType extends Enum<WorkationRenewalType>() {
  static Auto = new WorkationRenewalType(1, '自動的に更新する')
  static Sometimes = new WorkationRenewalType(2, '更新する場合がある')
  static Maybe = new WorkationRenewalType(3, '更新しない場合がある')
}

export class WorkationSpanGroupType extends Enum<WorkationSpanGroupType>() {
  static Pattern = new WorkationSpanGroupType(1, 'パターン')
  static Specific = new WorkationSpanGroupType(2, '個別表示')
}
export class WorkationSpanOptionType extends Enum<WorkationSpanOptionType>() {
  static members = [
    new WorkationSpanOptionType('パターン20 3～7.5時間 2～6日', true, true, 20),
    new WorkationSpanOptionType('パターン30 4～7.5時間 4～6日', true, true, 30),
    new WorkationSpanOptionType('パターン40 6～7.5時間 5～6日', true, true, 40),
    new WorkationSpanOptionType('個別20 2時間 6日', true, false, 20),
    new WorkationSpanOptionType('個別20 3時間 6日', true, false, 20),
    new WorkationSpanOptionType('個別20 4時間 4日', true, false, 20),
    new WorkationSpanOptionType('個別20 5時間 3日', true, false, 20),
    new WorkationSpanOptionType('個別20 6時間 3日', true, false, 20),
    new WorkationSpanOptionType('個別20 7時間 2日', true, false, 20),
    new WorkationSpanOptionType('個別20 7.5時間 2日', true, false, 20),
    new WorkationSpanOptionType('個別30 4時間 6日', true, false, 30),
    new WorkationSpanOptionType('個別30 5時間 5日', true, false, 30),
    new WorkationSpanOptionType('個別30 6時間 4日', true, false, 30),
    new WorkationSpanOptionType('個別30 7時間 4日', true, false, 30),
    new WorkationSpanOptionType('個別30 7.5時間 3日', true, false, 30),
    new WorkationSpanOptionType('個別40 6時間 6日', true, false, 40),
    new WorkationSpanOptionType('個別40 7時間 5日', true, false, 40),
    new WorkationSpanOptionType('個別40 7.5時間 5日', true, false, 40),
    new WorkationSpanOptionType('パターン20 3～8時間 2～5日', false, true, 20),
    new WorkationSpanOptionType('パターン30 4～8時間 3～5日', false, true, 30),
    new WorkationSpanOptionType('パターン40 8時間 5日', false, true, 40),
    new WorkationSpanOptionType('個別20 2時間 6日', false, false, 20),
    new WorkationSpanOptionType('個別20 3時間 6日', false, false, 20),
    new WorkationSpanOptionType('個別20 4時間 4日', false, false, 20),
    new WorkationSpanOptionType('個別20 5時間 3日', false, false, 20),
    new WorkationSpanOptionType('個別20 6時間 3日', false, false, 20),
    new WorkationSpanOptionType('個別20 7時間 2日', false, false, 20),
    new WorkationSpanOptionType('個別20 8時間 2日', false, false, 20),
    new WorkationSpanOptionType('個別30 4時間 6日', false, false, 20),
    new WorkationSpanOptionType('個別30 5時間 5日', false, false, 20),
    new WorkationSpanOptionType('個別30 6時間 4日', false, false, 20),
    new WorkationSpanOptionType('個別30 7時間 4日', false, false, 20),
    new WorkationSpanOptionType('個別30 8時間 3日', false, false, 20),
    new WorkationSpanOptionType('個別40 6時間 6日', false, false, 20),
    new WorkationSpanOptionType('個別40 7時間 5日', false, false, 20),
    new WorkationSpanOptionType('個別40 8時間 5日', false, false, 20),
  ]
  public static get list(): WorkationSpanOptionType[] {
    return this.members
  }

  isSeven: boolean
  isPattern: boolean
  isClass: number
  constructor(
    label: string,
    isSeven: boolean,
    isPattern: boolean,
    isClass: number,
  ) {
    super(label, label)
    this.isSeven = isSeven
    this.isPattern = isPattern
    this.isClass = isClass
  }

  get parsed() {
    return this.label.split(' ')
  }

  get weelHolidayCount() {
    const workDayParts = this.parsed[2]
    if (!this.isPattern) {
      const count = Number(workDayParts[0])
      return `週${7 - count}日`
    }
    const count1 = Number(workDayParts[0])
    const count2 = Number(workDayParts[2])
    return `週${7 - count1}～${7 - count2}日`
  }

  get classLabel() {
    const classlabels: { [name: number]: string } = {
      20: '1ヶ月の平均で週労働時間20時間未満。1ヶ月のシフトにより調整',
      30: '1ヶ月の平均で週労働時間20時間以上30時間未満。1ヶ月のシフトにより調整',
      40: '1ヶ月の平均で週労働時間30時間以上40時間未満。1ヶ月のシフトにより調整',
    }
    return classlabels[this.isClass]
  }
}

export class WorkationRestType extends Enum<WorkationRestType>() {
  static OneHour = new WorkationRestType(1, '1時間（6時間の勤務を超える場合）')
  static OneQuaterHour = new WorkationRestType(
    2,
    '1時間15分（6時間の勤務を超える場合）',
  )
}

export class WorkationSalaryType extends Enum<WorkationSalaryType>() {
  static Month = new WorkationSalaryType(1, '月給')
  static Hour = new WorkationSalaryType(2, '時給')
  static Day = new WorkationSalaryType(3, '日給')
  static Lesson = new WorkationSalaryType(4, 'レッスン給')
}
export class WorkationSalaryUnitType extends Enum<WorkationSalaryUnitType>() {
  // WorkationSalaryTypeと1～4一致前提
  static Month = new WorkationSalaryUnitType(1, '月')
  static Hour = new WorkationSalaryUnitType(2, '時')
  static Day = new WorkationSalaryUnitType(3, '日')
  static Lesson = new WorkationSalaryUnitType(4, 'レッスン')
}

export class ServantSchoolHistory extends Enum<ServantSchoolHistory>() {
  static Shou = new ServantSchoolHistory(0, '小学校')
  static Chuu = new ServantSchoolHistory(1, '中学校')
  static KouKou = new ServantSchoolHistory(2, '高等学校')
  static KouSen = new ServantSchoolHistory(3, '高等専門学校')
  static Tanki = new ServantSchoolHistory(4, '短期大学')
  static Dai = new ServantSchoolHistory(5, '大学')
  static Senmon = new ServantSchoolHistory(6, '専門学校')
  static Other = new ServantSchoolHistory(7, 'その他')
}

export class ServantSmoking extends Enum<ServantSmoking>() {
  static NotYet = new ServantSmoking(1, '喫煙していません。')
  static Doing = new ServantSmoking(
    2,
    '現在禁煙を実施しています。入社後も禁煙を守ります。',
  )
  static Charrange = new ServantSmoking(
    3,
    '現在喫煙しています。入社後は禁煙を実行致します。',
  )
}

export class ServantTransition extends Enum<ServantTransition>() {
  static Manual = new ServantTransition(1, '手入力')
  static Range = new ServantTransition(2, '交通機関定期券他')
  static Km2 = new ServantTransition(3, '交通用具使用（10km＞距離≧2km）')
  static Km10 = new ServantTransition(4, '交通用具使用（15km＞距離≧10km）')
  static Km15 = new ServantTransition(5, '交通用具使用（25km＞距離≧15km）')
  static Km25 = new ServantTransition(6, '交通用具使用（35km＞距離≧25km）')
  static Km35 = new ServantTransition(7, '交通用具使用（45km＞距離≧35km）')
  static Km45 = new ServantTransition(8, '交通用具使用（55km＞距離≧45km）')
  static Km55 = new ServantTransition(9, '交通用具使用（距離≧55km）')
}

export class ServantTransMethod extends Enum<ServantTransMethod>() {
  static Walk = new ServantTransMethod(1, '自転車 or 徒歩')
  static Bike = new ServantTransMethod(2, '自動二輪 or 原動機付自転車')
  static Car = new ServantTransMethod(3, '軽自動車 or 普通自動車')
  static Common = new ServantTransMethod(4, '公共交通機関')
}

export class ServantTransferModelType extends Enum<ServantTransferModelType>() {
  static BikeNormal = new ServantTransferModelType(1, '自動二輪')
  static BikeLight = new ServantTransferModelType(2, '原動機付自転車')
  static CarLight = new ServantTransferModelType(3, '軽自動車')
  static CarNormal = new ServantTransferModelType(4, '普通自動車')
  static CarOther = new ServantTransferModelType(5, 'その他')
}
