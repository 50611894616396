import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import { merge } from '@/util'
import { TMemberComment } from '@/types/typeMemberComment'

export interface CommentState {
  tMemberComments: TMemberComment[]
}

const module: StoreModule<CommentState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      tMemberComments: [],
    }
  },
  getters: {},
  mutations: {
    pushComments(
      state,
      payload: { memberId: number; tMemberComments: TMemberComment[] },
    ) {
      state.tMemberComments = state.tMemberComments
        .filter(row => row.memberId !== payload.memberId)
        .concat(payload.tMemberComments)
    },
    mergeComment(state, tMemberComment: TMemberComment) {
      state.tMemberComments = merge(state.tMemberComments, tMemberComment)
    },
    removeComment(state, commentId: number) {
      state.tMemberComments = state.tMemberComments.filter(
        row => row.id !== commentId,
      )
    },
  },
  actions: {
    async forgeComments({ commit }, memberId: number) {
      const tMemberComments = await TMemberComment.fetch(memberId)
      commit('pushComments', { memberId, tMemberComments })
    },
    receiveComment({ commit }, payload) {
      const tMemberComment = new TMemberComment(payload)
      commit('mergeComment', tMemberComment)
    },
    removeComment({ commit }, commentId) {
      commit('removeComment', Number(commentId))
    },
  },
}
export default module
