import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import { AuthForm, AuthUser } from '@/types/typeAuth'
import { UserRole } from '@/types/typeEnum'

export interface AuthState {
  authUser: AuthUser | null
}

const module: StoreModule<AuthState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      authUser: null,
    }
  },
  getters: {
    isLimitedRole(state): boolean {
      return state.authUser ? state.authUser.role === UserRole.Limited : false
    },
    hasAdminRole(state): boolean {
      return state.authUser ? state.authUser.role === UserRole.Admin : false
    },
    authAreas(state, getters, rootState) {
      const tAreas = rootState.storeArea?.tAreas || []
      if (getters.hasAdminRole) {
        return tAreas
      }
      const areaIds = state.authUser?.areas || []
      return tAreas.filter(row => areaIds.includes(row.id))
    },
  },
  mutations: {
    fill(state, payload: any) {
      Object.keys(payload).forEach(key => {
        // @ts-ignore
        state[key] = payload[key]
      })
    },
  },
  actions: {
    async getAuth({ commit }) {
      const authUser = await AuthUser.load()
      commit('fill', { authUser })
    },
    async login({ commit }, { authForm }: { authForm: AuthForm }) {
      const authUser = await authForm.login()
      commit('fill', { authUser })
    },
    async logout({ commit }) {
      await AuthUser.logout()
      commit('fill', { authUser: null })
    },
  },
}
export default module
