<template>
  <card-component class="is-card-widget">
    <div class="is-widget-label">
      <h3 class="subtitle mb-2">
        {{ label }}
      </h3>
      <div class="level is-mobile mb-1">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              <growing-number
                :value="number"
                :prefix="prefix"
                :suffix="suffix"
              />
            </h1>
          </div>
        </div>
        <div class="level-right">
          <div v-if="icon" class="level-item mr-4">
            <a @click="$emit('iconClick')">
              <b-icon :icon="icon" size="is-medium" :type="type" />
            </a>
          </div>
        </div>
      </div>
      <h3 class="subtitle is-spaced is-size-7 mt-3" v-if="description">
        {{ description }}
      </h3>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import GrowingNumber from '@/components/GrowingNumber'
export default {
  name: 'CardWidget',
  components: { GrowingNumber, CardComponent },
  props: {
    icon: {
      type: String,
      default: null,
    },
    number: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    type: {
      type: [String, Object],
      default: null,
    },
  },
}
</script>
