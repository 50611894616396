import { API, EndPoints } from '@/api'
import dayjs, { Dayjs } from 'dayjs'

export class TMemberResume {
  id: number
  memberId: number
  basename: string
  filename: string
  uploadedAt: Dayjs

  constructor(data: any) {
    this.id = Number(data.id)
    this.memberId = Number(data.member_id)
    this.basename = data.basename
    this.filename = data.filename
    this.uploadedAt = dayjs(data.uploaded_at)
  }

  static async fetch(memberId: number) {
    const params = { member_id: memberId }
    return API.get(EndPoints.MemberResume, { params }).then(r =>
      (r.data.resumes as any[]).map(d => new TMemberResume(d)),
    )
  }

  static upload(memberId: number, file: File) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('member_id', memberId.toString())
    return API.post(EndPoints.MemberResume, formData, { headers }).then(
      ({ data }) => new TMemberResume(data.resume),
    )
  }

  delete() {
    const params = { resume_id: this.id }
    return API.delete(EndPoints.MemberResume, { params })
  }

  get href() {
    return `${API.defaults.baseURL}${EndPoints.MemberResumeAsset}?resume_id=${this.id}`
  }
}
