import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
import ApplicantsView from '../views/ApplicantsView.vue'
import AbstructRouter from '../views/AbstructRouter.vue'
import { UnAuthoriedException } from '@/config/exception'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../views/FullPage.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('../views/full-page/Login.vue'),
      },
    ],
  },
  {
    path: '/',
    component: AbstructRouter,
    beforeEnter: (to, from, next) => {
      Promise.all([
        Store.dispatch('storeArea/loadAreas'),
        Store.dispatch('storeAuth/getAuth'),
      ])
        .then(() => {
          Store.dispatch('storeSocket/forgeStates')
          next()
        })
        .catch(err => {
          if (err instanceof UnAuthoriedException) {
            next({ name: 'Login' })
            return
          }
          console.log(err)
          next({ name: 'Error' })
        })
    },
    children: [
      {
        meta: {
          title: 'トップ',
        },
        path: '',
        redirect: { name: 'ApplicantsView' },
      },
      {
        meta: {
          title: '応募者管理',
        },
        path: 'applicants',
        name: 'ApplicantsView',
        component: ApplicantsView,
      },
      {
        meta: {
          title: '応募者詳細',
        },
        path: 'applicants/applicant/:code',
        name: 'ApplicantView',
        component: () => import('../views/ApplicantView.vue'),
        props: true,
      },
      {
        path: 'qrcodes',
        component: () => import('../views/FullPage.vue'),
        children: [
          {
            path: '',
            name: 'QRCodesView',
            component: () => import('../views/full-page/QRCodesView.vue'),
          },
        ],
      },
      {
        meta: {
          title: '採用管理',
        },
        path: 'candidates',
        name: 'CandidatesView',
        component: () => import('../views/CandidatesView.vue'),
      },
      {
        meta: {
          title: '採用候補者',
        },
        path: 'candidates/candidate/:code',
        name: 'CandidateView',
        component: () => import('../views/CandidateView.vue'),
        props: true,
      },
      {
        meta: {
          title: '通知書設定',
        },
        path: 'candidates/workation/:code',
        name: 'WorkationView',
        component: () => import('../views/WorkationView.vue'),
        props: true,
      },
      {
        meta: {
          title: '応募情報確認',
        },
        path: 'applicant_history/:pathMemberId',
        name: 'ApplicantHistoryView',
        component: () => import('../views/ApplicantHistoryView.vue'),
        props: true,
      },
      {
        meta: {
          title: '入社フォーム確認',
        },
        path: 'servant/:pathMemberId',
        name: 'ServantView',
        component: () => import('../views/ServantView.vue'),
        props: true,
      },
      {
        meta: {
          title: '採用済リスト',
        },
        path: 'succcess',
        name: 'SuccessView',
        component: () => import('../views/SuccessView.vue'),
        props: true,
      },
      {
        meta: {
          title: '採用済リスト',
        },
        path: 'ng',
        name: 'NGView',
        component: () => import('../views/NGView.vue'),
        props: true,
      },
      {
        path: 'master',
        component: AbstructRouter,
        props: true,
        beforeEnter: (to, from, next) => {
          if (Store.getters['storeAuth/isLimitedRole']) {
            next({ name: 'ApplicantsView' })
            return
          }
          next()
        },
        children: [
          {
            // マスタ：インスト設定
            path: 'areas',
            name: 'AreasView',
            component: () => import('../views/AreasView.vue'),
            props: true,
          },
          {
            // マスタ：ユーザー設定
            path: 'users',
            name: 'Users',
            component: () => import('../views/Users.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              if (!Store.getters['storeAuth/hasAdminRole']) {
                next({ name: 'ApplicantsView' })
                return
              }
              next()
            },
          },
        ],
      },
    ],
  },
  {
    path: '/full-page',
    component: () => import('../views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'Error',
        },
        path: '/error',
        name: 'Error',
        component: () => import('../views/full-page/Error.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
