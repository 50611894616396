
import Vue from 'vue'
import Tiles from '@/components/Tiles.vue'
import CardWidget from '@/components/CardWidget.vue'
import CardComponent from '@/components/CardComponent.vue'
import ApplicantsTable from '@/components/ApplicantsView/ApplicantsTable.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { mapActions, mapGetters } from 'vuex'
import { Mixin } from '@/mixin'
import { ApplicantTableFilter, TMember } from '@/types/typeMember'
import { TArea } from '@/types/typeArea'
import HeroBar from '@/components/HeroBar.vue'
export default Vue.extend({
  components: {
    CardToolbar,
    ApplicantsTable,
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
  },
  computed: {
    ...Mixin.computed,
    storeDataStatus(): boolean {
      return this.storeState.storeMember!.dataStatus
    },
    ...mapGetters('storeAuth', ['hasAdminRole', 'authAreas']),
    authAreaIds(): number[] {
      return (this.authAreas as TArea[]).map(row => row.id)
    },
    storedTMembers(): TMember[] {
      return this.storeState.storeMember!.tMembers
    },
    tMembers(): TMember[] {
      return this.storedTMembers.filter(row => {
        if (!row.isActive || !row.isStatusApplicantable) {
          return false
        }
        if (this.hasAdminRole) {
          return true
        }
        return this.authAreaIds.includes(row.areaId)
      })
    },
    applicantTableFilter(): ApplicantTableFilter {
      return this.storeState.storeMember!.applicantTableFilter
    },
    interViewedCount(): number {
      return this.tMembers.filter(row => row.isStatusInterviewed).length
    },
    alertCount(): number {
      return this.tMembers.filter(row => row.isAlert).length
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('storeMember', ['loadMembers']),
  },
  created() {
    if (!this.storeDataStatus) {
      this.isLoading = true
    }
    this.loadMembers({ force: false }).then(() => {
      this.isLoading = false
    })
  },
})
