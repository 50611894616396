
import Vue from 'vue'
import { Mixin } from '@/mixin'
import CardComponent from '@/components/CardComponent.vue'
import { UserPasswordForm } from '@/types/typeAuth'

export default Vue.extend({
  components: { CardComponent },
  data(): {
    isLoading: boolean
    form: UserPasswordForm
  } {
    return {
      isLoading: false,
      form: new UserPasswordForm(),
    }
  },
  methods: {
    ...Mixin.methods,
    willSave() {
      this.isLoading = true
      this.form
        .save()
        .then(() => {
          this.alertSuccess('パスワードを変更しました')
          this.$emit('close')
          this.isLoading = false
        })
        .catch(this.handleError)
    },
  },
})
