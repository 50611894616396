import { API, EndPoints } from '@/api'
import { DateFormat, DatetimeFormat } from '@/config/const'
import dayjs, { Dayjs } from 'dayjs'
import { MemberStatus, MemberGender, MemberNGAction, WorkationLicense } from './typeEnum'

export class TMember {
  id: number
  status: MemberStatus
  enquetedAt: Dayjs | null
  isActive: boolean
  code: string
  areaId: number
  tel: string
  birthday: Dayjs
  age: number
  sei: string
  mei: string
  seiKana: string
  meiKana: string
  mail: string
  gender: MemberGender | null
  expects: number[]
  note: string
  employeeNumber: number | null
  employeeNumberAt: Dayjs | null
  isAlert: boolean
  alertText: string
  ngAction: MemberNGAction | null
  ngNote: string
  ngAt: Dayjs | null
  createdAt: Dayjs
  updatedAt: Dayjs
  reincarnationNo: number
  summary: string
  recruitDate: Dayjs | null
  license: WorkationLicense | null = null


  filterWord: string

  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.status = MemberStatus.of(data.status)
    this.enquetedAt = data.enqueted_at
      ? dayjs(data.enqueted_at, DatetimeFormat)
      : null
    this.isActive = Number(data.active) === 1
    this.code = data.code || '00000000'
    this.areaId = Number(data.area_id) || 0
    this.tel = data.tel || ''
    this.birthday = dayjs(data.birthday, DateFormat)
    this.age = dayjs().diff(this.birthday, 'year')
    this.sei = data.sei || ''
    this.mei = data.mei || ''
    this.seiKana = data.sei_kana || ''
    this.meiKana = data.mei_kana || ''
    this.mail = data.mail || ''
    this.gender = data.gender ? MemberGender.of(data.gender) : null
    this.expects = data.expects
    this.note = data.note
    this.employeeNumber = data.employee_number ? Number(data.employee_number) : null
    this.employeeNumberAt = data.employee_number_at
      ? dayjs(data.employee_number_at, DatetimeFormat)
      : null
    this.isAlert = Number(data.alert) === 1
    this.alertText = data.alert_text
    this.ngAction = data.ng_action ? MemberNGAction.of(data.ng_action) : null
    this.ngNote = data.ng_note || ''
    this.ngAt = data.ng_at ? dayjs(data.ng_at) : null
    this.createdAt = dayjs(data.created_at)
    this.updatedAt = dayjs(data.updated_at)
    this.reincarnationNo = Number(data.reincarnation_no) || 1
    this.summary = data.summary || ''
    this.recruitDate = data.recruit_date ? dayjs(data.recruit_date, DateFormat) : null
    this.license = data.license ? WorkationLicense.of(data.license) : null

    this.filterWord = `${this.code}:${this.name}:${this.kana}:${this.tel}:${this.note}:${this.employeeNumber}`
  }

  get name() {
    return `${this.sei}${this.mei}`
  }

  get kana() {
    return `${this.seiKana}${this.meiKana}`
  }

  get genderLabel() {
    return this.gender ? this.gender.label : ''
  }

  get isStatusApply() {
    return this.status === MemberStatus.Apply
  }

  get isEnqueted() {
    return this.enquetedAt !== null
  }

  get isStatusInterviewed() {
    return this.status === MemberStatus.Interviewed
  }

  get isStatusApplicantable() {
    return this.isStatusApply || this.isStatusInterviewed
  }
  get isStatusRecruitable() {
    return this.status.in([
      'Recruit',
      'Workation',
      'Flow',
      'Approval',
      'Prepare',
    ])
  }
  get isStatusWorkationEditable() {
    // FIXME
    return this.status.in(['Recruit', 'Workation', 'Approval', 'Prepare'])
  }
  get isStatusServantable() {
    return this.status.in(['Approval', 'Prepare'])
  }
  get isStatusPrepare() {
    return this.status === MemberStatus.Prepare
  }
  get isStatusSuccess() {
    return this.status === MemberStatus.Success
  }
  get isRetry() {
    return this.reincarnationNo > 1
  }

  static async fetch() {
    return API.get(EndPoints.Member).then(r =>
      (r.data.members as any[]).map(d => new TMember(d)),
    )
  }
  static async fetchSuccess() {
    const params = { for: 'success' }
    return API.get(EndPoints.Member, { params }).then(r =>
      (r.data.members as any[]).map(d => new TMember(d)),
    )
  }
  static async fetchNgList() {
    const params = { for: 'ng' }
    return API.get(EndPoints.Member, { params }).then(r =>
      (r.data.members as any[]).map(d => new TMember(d)),
    )
  }
  static async read(id: number) {
    const params = { id }
    return API.get(EndPoints.Member, { params }).then(({ data }) =>
      data.member ? new TMember(data.member) : null,
    )
  }

  updateNote(note: string) {
    const member = {
      id: this.id,
      note: note,
    }
    return API.put(EndPoints.Member, { member }).then(r => {
      const data = r.data.member
      this.note = data.note
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  updateSummary(summary: string) {
    const member = {
      id: this.id,
      summary,
    }
    return API.put(EndPoints.Member, { member }).then(r => {
      const data = r.data.member
      this.summary = data.summary
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  updateEmployeeNumber(employeeNumber: string, force: boolean) {
    const employee_number = employeeNumber ? Number(employeeNumber) : null
    return API.put(EndPoints.MemberEmployeeNumber, { member_id: this.id, employee_number, force: force ? 1 : 0  }).then(r => {
      const data = r.data.member
      this.employeeNumber = data.employee_number ? Number(data.employee_number) : null
      this.employeeNumberAt = dayjs(data.employee_number_at, DatetimeFormat)
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  changeNG(ngAction: MemberNGAction, ngNote: string) {
    const ng = {
      id: this.id,
      ng_action: ngAction.value,
      ng_note: ngNote,
    }
    return API.post(EndPoints.MemberNG, { ng }).then(r => {
      const data = r.data.member
      this.isActive = Number(data.active) === 1
      this.ngAction = data.ng_action ? MemberNGAction.of(data.ng_action) : null
      this.ngNote = data.ng_note || ''
      this.ngAt = data.ng_at ? dayjs(data.ng_at) : null
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  updateArea(areaId: number) {
    const member = {
      id: this.id,
      area_id: areaId,
    }
    return API.put(EndPoints.MemberArea, { member }).then(r => {
      const data = r.data.member
      this.areaId = Number(data.area_id)
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  updateExpects(expects: number[]) {
    const member = {
      id: this.id,
      expects: expects,
    }
    return API.put(EndPoints.Member, { member }).then(r => {
      const data = r.data.member
      this.expects = data.expects
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  updateAlert(alert: boolean, alertText: string) {
    const member = {
      id: this.id,
      alert,
      alert_text: alert ? alertText : '',
    }
    return API.put(EndPoints.Member, { member }).then(r => {
      const data = r.data.member
      this.isAlert = Number(data.alert) === 1
      this.alertText = data.alert_text
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  changeStatusRecruit() {
    const recruit = {
      id: this.id,
    }
    return API.post(EndPoints.MemberStatusRecruit, { recruit }).then(r => {
      const data = r.data.member
      this.status = MemberStatus.of(data.status)
      this.summary = data.summary || ''
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  changeStatusWorkflow(willStatus: MemberStatus) {
    const workflow = {
      id: this.id,
      status: willStatus.value,
    }
    return API.put(EndPoints.MemberStatusWorkflow, { workflow }).then(r => {
      const data = r.data.member
      this.status = MemberStatus.of(data.status)
      this.updatedAt = dayjs(data.updated_at)
      return this
    })
  }

  sendMail(title: string, text: string) {
    const mail = {
      member_id: this.id,
      title,
      text,
    }
    return API.post(EndPoints.MemberMail, { mail })
  }

  get workationPath() {
    return API.getUri({
      url: `${API.defaults.baseURL}${EndPoints.MemberWorkationZip}`,
      params: { member_id_list: this.id.toString() },
    })
  }

  static workationPaths(member_ids: number[]) {
    return API.getUri({
      url: `${API.defaults.baseURL}${EndPoints.MemberWorkationZip}`,
      params: { member_id_list: member_ids.join(',') },
    })
  }

  get exportZipPath() {
    return API.getUri({
      url: `${API.defaults.baseURL}${EndPoints.MemberExportZip}`,
      params: { member_id_list: this.id.toString() },
    })
  }

  static exportZipPaths(member_ids: number[]) {
    return API.getUri({
      url: `${API.defaults.baseURL}${EndPoints.MemberExportZip}`,
      params: { member_id_list: member_ids.join(',') },
    })
  }

  static changeStatusSuccess(memberIds: number[]) {
    return API.post(EndPoints.MemberStatusSuccess, {
      member_ids: memberIds,
    }).then(({ data }) => {
      return {
        tMembers: (data.members as any[]).map(d => new TMember(d)),
      }
    })
  }

  static checkEmployeeNumber(){
    return API.get(EndPoints.MemberEmployeeNumber).then(({ data }) => {
      return data.employee_number ? Number(data.employee_number) : null
    })
  }
}

export class ApplicantTableFilter {
  currentPage = 1
  statusApply = true
  statusInterviewed = true
  checkedIds: number[] = []
  checkOnly: boolean = false
  alertOnly = false
  text: string = ''

  test(tMember: TMember): boolean {
    if (this.alertOnly && !tMember.isAlert) {
      return false
    }
    if (!this.statusApply && tMember.isStatusApply) {
      return false
    }
    if (!this.statusInterviewed && tMember.isStatusInterviewed) {
      return false
    }
    if (this.checkOnly && !this.checkedIds.includes(tMember.id)) {
      return false
    }
    if (this.text && !tMember.filterWord.includes(this.text)) {
      return false
    }
    return true
  }
}

export class CandidateTableFilter {
  currentPage = 1
  status: MemberStatus | null = null
  checkedIds: number[] = []
  checkOnly: boolean = false
  alertOnly = false
  text: string = ''

  test(tMember: TMember): boolean {
    if (this.alertOnly && !tMember.isAlert) {
      return false
    }
    if (this.status && tMember.status !== this.status) {
      return false
    }
    if (this.checkOnly && !this.checkedIds.includes(tMember.id)) {
      return false
    }
    if (this.text && !tMember.filterWord.includes(this.text)) {
      return false
    }
    return true
  }
}

export class MemberTableFilter {
  currentPage = 1
  text: string = ''
  recruitDateFrom: Dayjs | undefined = undefined
  recruitDateTo: Dayjs | undefined = undefined

  checkedIds: number[] = []

  test(tMember: TMember): boolean {
    if (this.text && !tMember.filterWord.includes(this.text)) {
      return false
    }
    if (this.recruitDateFrom) {
      if (!tMember.recruitDate || tMember.recruitDate.isBefore(this.recruitDateFrom)) {
        return false
      }
    }
    if (this.recruitDateTo) {
      if (!tMember.recruitDate || tMember.recruitDate.isAfter(this.recruitDateTo)) {
        return false
      }
    }
    return true
  }

  setCurrentPage(page: number){
    this.currentPage = page
  }
}

export class CandidateCreateForm {
  areaId: number | null = null
  sei: string = ''
  mei: string = ''
  seiKana: string = ''
  meiKana: string = ''
  tel: string = ''
  mail: string = ''
  birthday: Dayjs = dayjs('2000-01-01')
  gender: MemberGender | null = null
  summary: string = ''

  get params() {
    return {
      area_id: this.areaId || 0,
      sei: this.sei,
      mei: this.mei,
      sei_kana: this.seiKana,
      mei_kana: this.meiKana,
      tel: this.tel,
      mail: this.mail,
      birthday: this.birthday.format(DateFormat),
      gender: this.gender ? this.gender.value : null,
      summary: this.summary,
    }
  }
  save() {
    return API.post(EndPoints.Candidate, { member: this.params }).then(
      ({ data }) => new TMember(data.member),
    )
  }
}

export class CandidateEditForm {
  tMember: TMember
  sei: string = ''
  mei: string = ''
  seiKana: string = ''
  meiKana: string = ''
  tel: string = ''
  mail: string = ''
  birthday: Dayjs = dayjs('2000-01-01')
  gender: MemberGender | null = null

  constructor(tMember: TMember) {
    this.tMember = tMember
    this.sei = tMember.sei
    this.mei = tMember.mei
    this.seiKana = tMember.seiKana
    this.meiKana = tMember.meiKana
    this.tel = tMember.tel
    this.mail = tMember.mail
    this.birthday = tMember.birthday
    this.gender = tMember.gender
  }

  get params() {
    return {
      id: this.tMember.id,
      sei: this.sei,
      mei: this.mei,
      sei_kana: this.seiKana,
      mei_kana: this.meiKana,
      tel: this.tel,
      mail: this.mail,
      birthday: this.birthday.format(DateFormat),
      gender: this.gender ? this.gender.value : null,
    }
  }
  save() {
    return API.put(EndPoints.Candidate, { member: this.params }).then(
      ({ data }) => new TMember(data.member),
    )
  }
}
