<template>
  <div id="app">
    <NavBar />
    <AsideMenu
      :menu="menu"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :class="{ 'has-secondary': !!menuSecondary }"
    />
    <AsideMenu
      v-if="menuSecondary"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryClose"
    />
    <router-view />
    <Overlay @overlay-click="overlayClick" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import Overlay from '@/components/Overlay'
import { Mixin } from './mixin'

export default {
  name: 'App',
  components: {
    Overlay,
    AsideMenu,
    NavBar,
  },
  data() {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null,
    }
  },
  computed: {
    ...Mixin.computed,
    ...mapGetters('storeAuth', ['isLimitedRole', 'hasAdminRole']),
    menu() {
      const publics = [
        {
          to: { name: 'ApplicantsView' },
          icon: 'account-tie',
          label: '応募者管理',
          updateMark: true,
        },
        {
          to: { name: 'CandidatesView' },
          label: '採用管理',
          icon: 'table',
        },
        {
          label: '履歴',
          subLabel: '履歴',
          icon: 'clock-outline',
          menu: [
            {
              to: { name: 'SuccessView' },
              label: '採用済リスト',
              icon: 'star',
            },
            {
              to: { name: 'NGView' },
              label: 'NG済リスト',
              icon: 'delete-forever',
            },
          ],
        },
      ]
      if (this.isLimitedRole) {
        return [publics]
      }
      const master = {
        label: 'マスタ管理',
        subLabel: 'マスタ管理',
        icon: 'database',
        menu: [
          {
            to: { name: 'AreasView' },
            label: '施設管理',
            icon: 'bookmark-multiple-outline',
          },
        ],
      }
      if (this.hasAdminRole) {
        master.menu.push({
          to: { name: 'Users' },
          label: 'アカウント管理',
          icon: 'account-multiple',
        })
      }
      return [publics, [master]]
    },
    menuBottom() {
      return []
    },
    ...mapState([
      'isOverlayVisible',
      'isLayoutBoxed',
      'isLayoutAsideHidden',
      'isLayoutMobile',
    ]),
  },
  mounted() {
    document.documentElement.classList.remove('has-spinner-active')
  },
  methods: {
    menuClick(item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary
        this.menuSecondaryLabel = item.menuSecondaryLabel
          ? item.menuSecondaryLabel
          : null
        this.menuSecondaryIcon = item.menuSecondaryIcon
          ? item.menuSecondaryIcon
          : null

        this.$store.commit('asideActiveForcedKeyToggle', item)
        this.$store.commit('overlayToggle', true)
      } else if (item.action && item.action === 'logout') {
        this.$buefy.toast.open({
          message: 'Log out clicked',
          type: 'is-danger',
          queue: false,
        })
      }
    },
    menuSecondaryClose() {
      this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
      this.$store.commit('asideActiveForcedKeyToggle', null)

      if (!this.isLayoutAsideHidden) {
        this.$store.commit('overlayToggle', false)
      }
    },
    overlayClick() {
      if (this.menuSecondary) {
        this.menuSecondaryClose()
      } else {
        this.$store.dispatch('asideCloseAll')
      }
    },
  },
  watch: {
    isLayoutMobile(newVal) {
      if (newVal) {
        this.$store.commit('layoutBoxedToggle', false)
      }
    },
  },
}
</script>
