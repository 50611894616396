
import { Mixin } from '@/mixin'
import { TArea } from '@/types/typeArea'
import { TAreaOption } from '@/types/typeAreaOption'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    id: Number,
    hideAreaId: Number,
  },
  computed: {
    ...Mixin.computed,
    ...mapGetters('storeArea', ['findTArea', 'findTAreaOption']),
    department(): TAreaOption | null {
      return this.findTAreaOption(this.id)
    },
    hideArea(): boolean {
      return !!this.department && this.hideAreaId === this.department.areaId
    },
    tArea(): TArea | null {
      if (this.department) {
        return this.findTArea(this.department.areaId)
      }
      return null
    },
  },
})
