export const genUniquekey = (() => {
  let key = 0
  return () => {
    key += 1
    return key
  }
})()

interface Mergable {
  id: number
}
export const merge = <T extends Mergable>(rows: T[], row: T): T[] => {
  return rows
    .filter(r => r.id !== row.id)
    .concat([row])
    .sort((a, b) => (a.id < b.id ? -1 : 1))
}

export const assign = (obj1: any, obj2: any) => {
  Object.keys(obj2).forEach(function(key) {
    if (key !== 'key' && Object.prototype.hasOwnProperty.call(obj1, key)) {
      obj1[key] = obj2[key]
    }
  })
}
