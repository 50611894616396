import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import { merge } from '@/util'
import { TAreaOption } from '@/types/typeAreaOption'
import { TArea } from '@/types/typeArea'

export interface AreaState {
  dataStatus: boolean
  tAreas: TArea[]
  tAreaOptions: TAreaOption[]
}

const module: StoreModule<AreaState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      dataStatus: false,
      tAreas: [],
      tAreaOptions: [],
    }
  },
  getters: {
    tAreaMap(state) {
      return state.tAreas.reduce((map, tArea) => {
        map[tArea.id] = tArea
        return map
      }, {} as { [name: number]: TArea })
    },
    findTArea: (state, getters) => (id: number) => {
      return id ? getters.tAreaMap[id] || null : null
    },
    tAreaOptionMap(state) {
      return state.tAreaOptions.reduce((map, tAreaOption) => {
        map[tAreaOption.id] = tAreaOption
        return map
      }, {} as { [name: number]: TAreaOption })
    },
    findTAreaOption: (state, getters) => (id: number) => {
      return id ? getters.tAreaOptionMap[id] || null : null
    },
  },
  mutations: {
    fill(state, payload: any) {
      Object.keys(payload).forEach(key => {
        // @ts-ignore
        state[key] = payload[key]
      })
    },
    mergeArea(state, tArea: TArea) {
      state.tAreas = merge(state.tAreas, tArea)
    },
    mergeAreaOption(state, tAreaOption: TAreaOption) {
      state.tAreaOptions = merge(state.tAreaOptions, tAreaOption)
    },
  },
  actions: {
    async loadAreas({ commit, state }, args: { force?: boolean }) {
      const force = args ? args.force : false
      if (state.dataStatus && !force) {
        return
      }
      commit('fill', { dataStatus: true })
      const [tAreas, tAreaOptions] = await Promise.all([
        TArea.fetch(),
        TAreaOption.fetch(),
      ])
      commit('fill', { tAreas, tAreaOptions })
    },
    receiveArea({ commit }, payload) {
      const tArea = new TArea(payload)
      commit('mergeArea', tArea)
    },
    receiveAreaOption({ commit }, payload) {
      const tAreaOption = new TAreaOption(payload)
      commit('mergeAreaOption', tAreaOption)
    },
  },
}
export default module
