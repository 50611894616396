import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import {
  ApplicantTableFilter,
  CandidateTableFilter,
  MemberTableFilter,
  TMember,
} from '@/types/typeMember'
import { merge } from '@/util'

export interface MemberState {
  dataStatus: boolean
  tMembers: TMember[]
  applicantTableFilter: ApplicantTableFilter
  candidateTableFilter: CandidateTableFilter
  successTableFilter: MemberTableFilter
  ngTableFilter: MemberTableFilter
}

const module: StoreModule<MemberState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      dataStatus: false,
      tMembers: [],
      applicantTableFilter: new ApplicantTableFilter(),
      candidateTableFilter: new CandidateTableFilter(),
      successTableFilter: new MemberTableFilter(),
      ngTableFilter: new MemberTableFilter(),
    }
  },
  getters: {
    tMemberCodeMap(state) {
      return state.tMembers.reduce((map, tMember) => {
        map[tMember.code] = tMember
        return map
      }, {} as { [name: string]: TMember })
    },
    findTMemberByCode: (state, getters) => (code: string) => {
      return code ? getters.tMemberCodeMap[code] || null : null
    },
  },
  mutations: {
    fill(state, payload: any) {
      Object.keys(payload).forEach(key => {
        // @ts-ignore
        state[key] = payload[key]
      })
    },
    mergeMember(state, tMember: TMember) {
      state.tMembers = merge(state.tMembers, tMember)
    },
    mergeMembers(state, tMembers: TMember[]) {
      const tMemberIds = tMembers.map(row => row.id)
      state.tMembers = state.tMembers
        .filter(row => !tMemberIds.includes(row.id))
        .concat(tMembers)
    },
  },
  actions: {
    async loadMembers({ commit, state }, args: { force: boolean }) {
      if (state.dataStatus && !args.force) {
        return
      }
      commit('fill', { dataStatus: true })
      const tMembers = await TMember.fetch()
      commit('fill', { tMembers })
    },
    async changeMemberSuccess({ commit }, memberIds: number[]) {
      const { tMembers } = await TMember.changeStatusSuccess(memberIds)
      commit('mergeMembers', tMembers)
    },
    receiveMember({ commit }, payload) {
      const tMember = new TMember(payload)
      commit('mergeMember', tMember)
    },
    receiveMembers({ commit }, payload) {
      const tMembers = (payload as any[]).map(data => new TMember(data))
      commit('mergeMembers', tMembers)
    },
  },
}
export default module
