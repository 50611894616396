import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import { WorkationForm } from '@/types/typeWorkation'
import { TMember } from '@/types/typeMember'
import { TAreaOption } from '@/types/typeAreaOption'
import { TArea } from '@/types/typeArea'
import { OFFICIAL_HOLIDAYS } from '@/config/const'
import { Dayjs } from 'dayjs'

export interface WorkationState {
  workationForm: WorkationForm
}

const module: StoreModule<WorkationState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      workationForm: new WorkationForm(),
    }
  },
  getters: {
    tArea(state, getters, rootState, rootGetters) {
      return rootGetters['storeArea/findTArea'](state.workationForm.areaId)
    },
    tAreaOptions(state, getters, rootState, rootGetters) {
      return rootState.storeArea!.tAreaOptions.filter(
        row => row.areaId === state.workationForm.areaId,
      )
    },
    /** 年齢 採用希望日時点 */
    calcAge(state) {
      const { recruitStartDate, birthday } = state.workationForm
      return recruitStartDate.diff(birthday, 'year')
    },
    /** 年度終了日 */
    workEndAt(state, getters) {
      const tArea: TArea = getters.tArea
      const { recruitStartDate } = state.workationForm
      const endAtDate = tArea.isDirect ? 15 : 31
      let endAt = recruitStartDate.month(2).date(endAtDate)
      if (endAt.isBefore(recruitStartDate)) {
        endAt = endAt.add(1, 'year')
      }
      return endAt
    },
    /** 主な勤務先がセイカ以外 */
    mainJobIsNotSeika(state) {
      const { hasSubJob, mainJobIsSeika } = state.workationForm
      return hasSubJob && !mainJobIsSeika
    },
    /** 保険計算値 */
    calcInsurance(state, getters) {
      const ret = {
        insuranceEmproyee: false,
        insuranceHelth: false,
        insuranceWelfare: false,
      }
      const { spanOptionType, isStudent } = state.workationForm
      if (!spanOptionType || isStudent || getters.mainJobIsNotSeika) {
        return ret
      }
      switch (spanOptionType.isClass) {
        case 20:
          // 「パターン２０、個別２０」の場合、「雇用保険・健康保険・厚生年金」スイッチが全てオフ
          ret.insuranceEmproyee = false
          ret.insuranceHelth = false
          ret.insuranceWelfare = false
          break
        case 30:
          ret.insuranceEmproyee = true
          break
        case 40:
          ret.insuranceEmproyee = true
          if (getters.calcAge >= 75) {
            ret.insuranceHelth = false
            ret.insuranceWelfare = false
          } else if (getters.calcAge >= 70) {
            ret.insuranceHelth = true
            ret.insuranceWelfare = false
          } else {
            ret.insuranceHelth = true
            ret.insuranceWelfare = true
          }
          break
        default:
          break
      }
      return ret
    },
    /** 年間公休リスト */
    allOfficialHolidays(state, getters) {
      const ret: Dayjs[] = []
      const workEndAt: Dayjs = getters.workEndAt
      OFFICIAL_HOLIDAYS.forEach((list, yearDiff) => {
        list.forEach(tpl => {
          ret.push(
            workEndAt
              .add(yearDiff - 1, 'year')
              .month(tpl[0] - 1)
              .date(tpl[1]),
          )
        })
      })
      return ret
    },
    /** 残 年間公休リスト */
    remainOfficialHolidays(state, getters) {
      const { recruitStartDate } = state.workationForm
      const allOfficialHolidays: Dayjs[] = getters.allOfficialHolidays
      return allOfficialHolidays.filter(
        d => !d.isBefore(recruitStartDate, 'date'),
      )
    },
    /** パターン40の場合の休日計算パラメータ 計算式表示と実際の計算に使用 */
    calcHoliday40Params(
      state,
      getters: {
        tArea: TArea
        workEndAt: Dayjs
        remainOfficialHolidays: Dayjs[]
      },
    ) {
      const { spanOptionType, recruitStartDate } = state.workationForm
      if (spanOptionType && spanOptionType.isClass === 40) {
        const areaHolidayCount = getters.tArea.holidayCount
        const remainWorkDayCount =
          getters.workEndAt.diff(recruitStartDate, 'day') + 1 // 開始日を含める
        const remainOfficialHolidayCount = getters.remainOfficialHolidays.length
        return {
          areaHolidayCount,
          remainWorkDayCount,
          remainOfficialHolidayCount,
        }
      }
      return null
    },
    /** 雇用パターン問わず、休日の値を出す */
    calcHolidayValue(state, getters) {
      const { spanOptionType } = state.workationForm
      if (!spanOptionType) {
        return ''
      }
      if (spanOptionType.isClass !== 40) {
        return spanOptionType.weelHolidayCount
      }
      const params = getters.calcHoliday40Params
      if (!params) {
        return null
      }
      const based = Math.floor(
        ((params.areaHolidayCount - 8) / 365) * params.remainWorkDayCount,
      )
      return `年間${based + params.remainOfficialHolidayCount}日`
    },
  },
  mutations: {
    fill(state, payload: any) {
      Object.keys(payload).forEach(key => {
        // @ts-ignore
        state[key] = payload[key]
      })
    },
  },
  actions: {
    async forgeState({ commit }, payload: { tMember: TMember; tArea: TArea }) {
      const workationForm = new WorkationForm().forgeBase(
        payload.tMember,
        payload.tArea,
      )
      await workationForm.load()
      commit('fill', { workationForm })
    },
  },
}
export default module
