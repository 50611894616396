import { API, EndPoints } from '@/api'
import { DateFormat } from '@/config/const'
import { genUniquekey } from '@/util'
import dayjs, { Dayjs } from 'dayjs'
import { TArea } from './typeArea'
import {
  AreaOptionType,
  AreaPaymentLimit,
  AreaShiftType,
  MemberGender,
  WorkationLicense,
  WorkationRenewalType,
  WorkationRestType,
  WorkationSalaryType,
  WorkationSalaryUnitType,
  WorkationSpanGroupType,
  WorkationSpanOptionType,
} from './typeEnum'
import { TMember } from './typeMember'

export class AreaOptionsForm {
  rowForms: AreaOptionRowForm[] = [
    new AreaOptionRowForm(AreaOptionType.Occupation),
    new AreaOptionRowForm(AreaOptionType.Content1),
    new AreaOptionRowForm(AreaOptionType.Content2),
  ]
  add(type: AreaOptionType) {
    this.rowForms.push(new AreaOptionRowForm(type))
  }
  remove(rowForm: AreaOptionRowForm) {
    this.rowForms = this.rowForms.filter(row => row !== rowForm)
  }
  fill(datas: any[]) {
    if (!datas) {
      return
    }
    this.rowForms = datas.map(data => AreaOptionRowForm.forge(data))
  }
  get params() {
    return this.rowForms.map(row => row.params)
  }
}
export class AreaOptionRowForm {
  key = genUniquekey()
  type: AreaOptionType
  optionId: number | null = null
  constructor(type: AreaOptionType) {
    this.type = type
  }
  get params() {
    return {
      type: this.type.value,
      option_id: this.optionId,
    }
  }
  static forge(data: any) {
    const type = AreaOptionType.of(data.type)
    const row = new AreaOptionRowForm(type)
    row.optionId = data.option_id ? Number(data.option_id) : null
    return row
  }
}

export class SalaryOtherForm {
  key = genUniquekey()
  /** 支給内容 */
  name = ''
  /** 支給額 */
  amount = ''

  get params() {
    return {
      name: this.name,
      amount: this.amount,
    }
  }

  fill(data: any) {
    this.name = data.name
    this.amount = data.amount
    return this
  }

  static forgeDefaults() {
    return [new SalaryOtherForm(), new SalaryOtherForm(), new SalaryOtherForm()]
  }
}

export class TWorkation {
  isTempStatus: boolean = false
  updatedAt: Dayjs
  data: any
  constructor(data: any) {
    this.isTempStatus = Number(data.status) === 0
    this.updatedAt = dayjs(data.updated_at)
    this.data = data || {}
  }
  static read(memberId: number) {
    const params = { member_id: memberId }
    return API.get(EndPoints.MemberWorkation, { params }).then(({ data }) =>
      data.workation ? new TWorkation(data.workation) : null,
    )
  }

  get recruitStartDate() {
    return this.data.recruit_start_date
      ? dayjs(this.data.recruit_start_date, DateFormat)
      : null
  }
}

export class WorkationForm {
  id: number = 0
  isTempStatus: boolean = false
  freeze: boolean = false
  memberId: number = 0

  /** ===基本設定=== */
  sei: string = ''
  mei: string = ''
  seiKana: string = ''
  meiKana: string = ''
  areaId: number = 1
  departmentId: number | null = null
  locationId: number | null = null
  areaOptionsForm = new AreaOptionsForm()
  license: WorkationLicense | null = null
  gender: MemberGender | null = null
  birthday = dayjs('1970-01-01')
  recruitStartDate = dayjs()
  recruitEndDate: Dayjs | null = null
  recruitNoEndFlag = false
  isStudent = false
  willSubJob = false
  hasSubJob = false
  mainJobIsSeika = true
  renewalFlag = false
  renewalType = WorkationRenewalType.Auto

  /** ===研修設定=== */
  trainingFlag = false
  trainingStartDate = dayjs()
  trainingEndDate = dayjs()
  trainingSalary = ''

  /** ===勤務設定=== */
  shiftStart: string | null = null
  shiftEnd: string | null = null
  shift1: string | null = null
  shift2: string | null = null
  shift3: string | null = null
  shift4: string | null = null
  /** 雇用種別 */
  spanGroupType: WorkationSpanGroupType = WorkationSpanGroupType.Pattern
  /** 雇用パターン */
  spanOptionType: WorkationSpanOptionType | null = null
  insuranceEmproyee = false
  insuranceHelth = false
  insuranceWelfare = false
  /** 就業時間に関する特記事項 */
  propertyNote = 'シフト表により勤務時間帯の変動あり。'
  /** 就業形態 施設連動 */
  propertyFormat: AreaShiftType = AreaShiftType.Free // tArea.shiftTypeで変動
  /** 休憩有無 */
  restFlag = true
  restType: WorkationRestType = WorkationRestType.OneHour
  /** 休日 計算値デフォ */
  holidayValue = ''
  /** 休日特記事項 */
  holidayNote = 'シフトにより調整'
  /** 時間外労働 */
  outShiftNote = '原則として命じない。'
  /** 休日労働 */
  outWorkdayNote = '原則として命じない。但し他の日と振替えることがある。'

  /** ===給与設定=== */
  /** 希望 基本給与 */
  salaryType = WorkationSalaryType.Month
  /** 基本給与 */
  salaryAmount = ''
  /** 給与単位
   * 基本給与と連動。変更不可
  */
  // salaryUnitType = WorkationSalaryUnitType.Month
  /** 給与-その他1~3 */
  salaryOtherForms = SalaryOtherForm.forgeDefaults()
  /** 昇給 */
  salaryUpFlag = false
  /** 賞与 */
  salaryBonusFlag = false
  /** 賞与規定 */
  salaryBonusNote = '会社業績による 年2回支給（1回基本給1ヶ月分）'
  /** 退職金 */
  salaryExitFlag = false
  /** 給与特記事項 */
  salaryNote = ''
  /** 賃金支払 施設連動 */
  salaryPaymentLimit = AreaPaymentLimit.MonthEnd

  /** ===その他=== */
  /** 所得税 */
  incomeTaxFlag = false
  /** セイカ会員 */
  directMemberFlag = false
  /** セイカ会員No */
  directMemberNo = ''
  otherNote1 = ''
  otherNote2 = ''
  otherNote3 = ''
  otherNote4 = ''
  otherNote5 = ''
  otherNote6 = ''
  otherNote7 = ''
  /** 通勤手当 */
  transNote = '会社業績による'

  forgeBase(tMember: TMember, tArea: TArea) {
    //from TMember
    this.memberId = tMember.id
    this.sei = tMember.sei
    this.mei = tMember.mei
    this.seiKana = tMember.seiKana
    this.meiKana = tMember.meiKana
    this.areaId = tMember.areaId
    if (tMember.expects && tMember.expects.length) {
      this.departmentId = tMember.expects[0]
    }
    this.gender = tMember.gender
    this.birthday = tMember.birthday
    // from TArea
    this.syncByArea(tArea)
    return this
  }
  fillData(data: any) {
    this.id = data.id
    this.isTempStatus = Number(data.status) === 0
    /** ===基本設定=== */
    this.sei = data.sei
    this.mei = data.mei
    this.seiKana = data.sei_kana
    this.meiKana = data.mei_kana
    this.areaId = data.area_id
    this.departmentId = data.department_id
    this.locationId = data.location_id
    this.areaOptionsForm.fill(data.area_options)
    this.license = data.license ? WorkationLicense.of(data.license) : null
    this.gender = data.gender ? MemberGender.of(data.gender) : null
    this.birthday = dayjs(data.birthday, DateFormat)
    this.recruitStartDate = dayjs(data.recruit_start_date, DateFormat)
    this.recruitEndDate = data.recruit_end_date
      ? dayjs(data.recruit_end_date, DateFormat)
      : null
    this.recruitNoEndFlag = Number(data.recruit_no_end_flag) === 1
    this.isStudent = Number(data.is_student) === 1
    this.willSubJob = Number(data.will_sub_job) === 1
    this.hasSubJob = Number(data.has_sub_job) === 1
    this.mainJobIsSeika = Number(data.main_job_is_seika) === 1
    this.renewalFlag = Number(data.renewal_flag) === 1
    this.renewalType = WorkationRenewalType.of(data.renewal_type)

    /** ===研修設定=== */
    this.trainingFlag = Number(data.training_flag) === 1
    this.trainingStartDate = dayjs(data.training_start_date, DateFormat)
    this.trainingEndDate = dayjs(data.training_end_date, DateFormat)
    this.trainingSalary = data.training_salary

    /** ===勤務設定=== */
    this.shiftStart = data.shift_start
    this.shiftEnd = data.shift_end
    this.shift1 = data.shift1
    this.shift2 = data.shift2
    this.shift3 = data.shift3
    this.shift4 = data.shift4
    this.spanGroupType = WorkationSpanGroupType.of(data.span_group_type)
    this.spanOptionType = data.span_option_type
      ? WorkationSpanOptionType.of(data.span_option_type)
      : null
    this.insuranceEmproyee = Number(data.insurance_emproyee) === 1
    this.insuranceHelth = Number(data.insurance_helth) === 1
    this.insuranceWelfare = Number(data.insurance_welfare) === 1
    this.propertyNote = data.property_note
    this.propertyFormat = AreaShiftType.of(data.property_format)
    this.restFlag = Number(data.rest_flag) === 1
    this.restType = WorkationRestType.of(data.rest_type)
    this.holidayValue = data.holiday_value
    this.holidayNote = data.holiday_note
    this.outShiftNote = data.out_shift_note
    this.outWorkdayNote = data.out_workday_note

    /** ===給与設定=== */
    this.salaryType = WorkationSalaryType.of(data.salary_type)
    this.salaryAmount = data.salary_amount
    // this.salaryUnitType = WorkationSalaryUnitType.of(data.salary_unit_type)
    this.salaryOtherForms = data.salary_others
      ? (data.salary_others as any[]).map(data =>
          new SalaryOtherForm().fill(data),
        )
      : this.salaryOtherForms
    this.salaryUpFlag = Number(data.salary_up_flag) === 1
    this.salaryBonusFlag = Number(data.salary_bonus_flag) === 1
    this.salaryBonusNote = data.salary_bonus_note
    this.salaryExitFlag = Number(data.salary_exit_flag) === 1
    this.salaryNote = data.salary_note
    this.salaryPaymentLimit = AreaPaymentLimit.of(data.salary_payment_limit)

    /** ===その他=== */
    this.incomeTaxFlag = Number(data.income_tax_flag) === 1
    this.directMemberFlag = Number(data.direct_member_flag) === 1
    this.directMemberNo = data.direct_member_no
    this.otherNote1 = data.other_note1
    this.otherNote2 = data.other_note2
    this.otherNote3 = data.other_note3
    this.otherNote4 = data.other_note4
    this.otherNote5 = data.other_note5
    this.otherNote6 = data.other_note6
    this.otherNote7 = data.other_note7
    this.transNote = data.trans_note
  }

  syncByArea(tArea: TArea) {
    this.propertyFormat = tArea.shiftType
    if (tArea.workationNotes.length >= 7) {
      this.otherNote1 = tArea.workationNotes[0]
      this.otherNote2 = tArea.workationNotes[1]
      this.otherNote3 = tArea.workationNotes[2]
      this.otherNote4 = tArea.workationNotes[3]
      this.otherNote5 = tArea.workationNotes[4]
      this.otherNote6 = tArea.workationNotes[5]
      this.otherNote7 = tArea.workationNotes[6]
    }
    this.salaryPaymentLimit = tArea.paymentLimit
    this.recruitNoEndFlag = tArea.isDirect
    this.restType = tArea.restType
    this.renewalFlag = tArea.isDirect
  }

  get areaIdAlt() {
    return this.areaId
  }
  set areaIdAlt(val: number) {
    this.areaId = val
    this.departmentId = null
    this.locationId = null
    this.areaOptionsForm = new AreaOptionsForm()
    this.spanOptionType = null
  }

  get willSubJobAlt() {
    return this.willSubJob
  }
  set willSubJobAlt(val) {
    this.willSubJob = val
    this.hasSubJob = val
    this.mainJobIsSeika = true
  }

  get spanGroupTypeAlt() {
    return this.spanGroupType
  }
  set spanGroupTypeAlt(val: WorkationSpanGroupType) {
    this.spanGroupType = val
    this.spanOptionType = null
  }

  get recruitNoEndFlagAlt() {
    return this.recruitNoEndFlag
  }
  set recruitNoEndFlagAlt(val: boolean) {
    this.recruitNoEndFlag = val
    if (!val) {
      this.recruitEndDate = null
    }
  }

  get salaryUnitType(){
   return WorkationSalaryUnitType.of(this.salaryType.value)
  }

  get params() {
    return {
      /** ===基本設定=== */
      sei: this.sei,
      mei: this.mei,
      sei_kana: this.seiKana,
      mei_kana: this.meiKana,
      area_id: this.areaId,
      department_id: this.departmentId,
      location_id: this.locationId,
      area_options: this.areaOptionsForm.params,
      license: this.license ? this.license.value : null,
      gender: this.gender ? this.gender.value : null,
      birthday: this.birthday.format(DateFormat),
      recruit_start_date: this.recruitStartDate.format(DateFormat),
      recruit_end_date:
        !this.recruitNoEndFlag && this.recruitEndDate
          ? this.recruitEndDate.format(DateFormat)
          : null,
      recruit_no_end_flag: this.recruitNoEndFlag ? 1 : 0,
      is_student: this.isStudent ? 1 : 0,
      will_sub_job: this.willSubJob ? 1 : 0,
      has_sub_job: this.hasSubJob ? 1 : 0,
      main_job_is_seika: this.mainJobIsSeika ? 1 : 0,
      renewal_flag: this.renewalFlag ? 1 : 0,
      renewal_type: this.renewalType.value,
      /** ===研修設定=== */
      training_flag: this.trainingFlag ? 1 : 0,
      training_start_date: this.trainingStartDate.format(DateFormat),
      training_end_date: this.trainingEndDate.format(DateFormat),
      training_salary: this.trainingSalary,

      /** ===勤務設定=== */
      shift_start: this.shiftStart,
      shift_end: this.shiftEnd,
      shift1: this.shift1,
      shift2: this.shift2,
      shift3: this.shift3,
      shift4: this.shift4,
      span_group_type: this.spanGroupType.value,
      span_option_type: this.spanOptionType ? this.spanOptionType.value : null,
      insurance_emproyee: this.insuranceEmproyee ? 1 : 0,
      insurance_helth: this.insuranceHelth ? 1 : 0,
      insurance_welfare: this.insuranceWelfare ? 1 : 0,
      property_note: this.propertyNote,
      property_format: this.propertyFormat.value,
      rest_flag: this.restFlag ? 1 : 0,
      rest_type: this.restType.value,
      holiday_value: this.holidayValue,
      holiday_note: this.holidayNote,
      out_shift_note: this.outShiftNote,
      out_workday_note: this.outWorkdayNote,

      /** ===給与設定=== */
      salary_type: this.salaryType.value,
      salary_amount: this.salaryAmount,
      salary_unit_type: this.salaryUnitType.value,
      salary_others: this.salaryOtherForms.map(row => row.params),
      salary_up_flag: this.salaryUpFlag ? 1 : 0,
      salary_bonus_flag: this.salaryBonusFlag ? 1 : 0,
      salary_bonus_note: this.salaryBonusNote,
      salary_exit_flag: this.salaryExitFlag ? 1 : 0,
      salary_note: this.salaryNote,
      salary_payment_limit: this.salaryPaymentLimit.value,

      /** ===その他=== */
      income_tax_flag: this.incomeTaxFlag ? 1 : 0,
      direct_member_flag: this.directMemberFlag ? 1 : 0,
      direct_member_no: this.directMemberNo,
      other_note1: this.otherNote1,
      other_note2: this.otherNote2,
      other_note3: this.otherNote3,
      other_note4: this.otherNote4,
      other_note5: this.otherNote5,
      other_note6: this.otherNote6,
      other_note7: this.otherNote7,
      trans_note: this.transNote,
    }
  }
  save(confirmed: boolean) {
    return API.post(EndPoints.MemberWorkation, {
      member_id: this.memberId,
      status: confirmed ? 1 : 0,
      workation: this.params,
    }).then(({ data }) => {
      this.fillData(data.workation)
      return {
        tMember: new TMember(data.member),
      }
    })
  }
  load() {
    const params = { member_id: this.memberId }
    return API.get(EndPoints.MemberWorkation, { params }).then(({ data }) => {
      if (data.workation) {
        this.fillData(data.workation)
      }
    })
  }
}
