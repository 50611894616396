import { API, EndPoints } from '@/api'
import { assign } from '@/util'
import { AreaOptionType } from './typeEnum'

export class LocationData {
  zipcode: string = ''
  address: string = ''
  fill(data: any) {
    this.zipcode = data.zipcode
    this.address = data.address
    return this
  }
}

export class DepartmentData {
  code1: string = ''
  code2: string = ''
  fill(data: any) {
    this.code1 = data.code1
    this.code2 = data.code2
    return this
  }
}

export class TAreaOption {
  id: number
  areaId: number
  type: AreaOptionType
  name: string
  no: number | null
  active: boolean
  departmentData: DepartmentData | null = null
  locationData: LocationData | null = null
  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.areaId = Number(data.area_id) || 0
    this.type = AreaOptionType.of(data.type)
    this.name = data.name || ''
    this.no = data.no !== null ? Number(data.no) : null
    this.active = data.active

    if (this.isLocation) {
      this.locationData = new LocationData()
      if (data.data) {
        this.locationData.fill(data.data)
      }
    }
    if (this.isDepeartment) {
      this.departmentData = new DepartmentData()
      if (data.data) {
        this.departmentData.fill(data.data)
      }
    }
  }

  static async fetch() {
    return API.get(EndPoints.AreaOption).then(r =>
      (r.data.options as any[]).map(d => new TAreaOption(d)),
    )
  }

  hide() {
    const option = {
      id: this.id,
      active: false,
    }
    return API.put(EndPoints.AreaOption, { option }).then(
      ({ data }) => new TAreaOption(data.option),
    )
  }

  get isLocation() {
    return this.type === AreaOptionType.Location
  }
  get isDepeartment() {
    return this.type === AreaOptionType.Department
  }
  get isOccupation() {
    return this.type === AreaOptionType.Occupation
  }
}

export class AreeOptionForm {
  areaId: number
  type: AreaOptionType
  id: number = 0
  name: string = ''
  departmentObj = {
    code1: '',
    code2: '',
  }
  locationObj = {
    zipcode: '',
    address: '',
  }
  constructor(areaId: number, type: AreaOptionType) {
    this.areaId = areaId
    this.type = type
  }
  fill(tAreaOption: TAreaOption) {
    this.id = tAreaOption.id
    this.name = tAreaOption.name
    if (this.isDepeartment && tAreaOption.departmentData) {
      this.departmentObj.code1 = tAreaOption.departmentData.code1
      this.departmentObj.code2 = tAreaOption.departmentData.code2
    }
    if (this.isLocation && tAreaOption.locationData) {
      this.locationObj.zipcode = tAreaOption.locationData.zipcode
      this.locationObj.address = tAreaOption.locationData.address
    }
  }

  get isDepeartment() {
    return this.type === AreaOptionType.Department
  }
  get isLocation() {
    return this.type === AreaOptionType.Location
  }

  get params() {
    let data = null
    if (this.isDepeartment) {
      data = this.departmentObj
    }
    if (this.isLocation) {
      data = this.locationObj
    }
    return {
      id: this.id,
      area_id: this.areaId,
      type: this.type.value,
      name: this.name,
      data,
    }
  }
  save() {
    if (this.id) {
      return API.put(EndPoints.AreaOption, { option: this.params }).then(
        ({ data }) => new TAreaOption(data.option),
      )
    }
    return API.post(EndPoints.AreaOption, { option: this.params }).then(
      ({ data }) => new TAreaOption(data.option),
    )
  }
}
