import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import {
  InValidServiceException,
  UnAuthoriedException,
  ConfirmServiceException,
} from '@/config/exception' // eslint-disable-line no-unused-vars

export const VID = uuidv4()

export const ApiSocketUri = process.env.VUE_APP_WS_HOST
  ? `${process.env.VUE_APP_WS_HOST}/api/websocket`
  : `${location.origin.replace(/^http/, 'ws')}/api/websocket`

export const API = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}`,
  withCredentials: true,
  headers: {
    vid: VID,
    'Content-Type': 'application/json;charset=utf-8',
  },
})

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        throw new UnAuthoriedException()
      }
      if (error.response.status === 409) {
        const { message } = error.response.data
        throw new ConfirmServiceException(message)
      }
      if (error.response.status === 400) {
        const { message, data } = error.response.data
        throw new InValidServiceException(data, message)
      }
    }
    throw new Error('unknown')
  },
)

export const EndPoints = {
  Auth: '/api/admin/auth.json',
  User: '/api/admin/user.json',
  Password: '/api/admin/password.json',
  CommonArea: '/api/common/area.json',
  CommonAreaBank: '/api/common/area/bank.json',
  CommonAttachmentAsset: '/api/common/attachment',
  Area: '/api/admin/area.json',
  AreaOption: '/api/admin/area_option.json',
  AreaBank: '/api/admin/area/bank.json',
  Member: '/api/admin/member.json',
  MemberReincarnation: '/api/admin/member/reincarnation.json',
  Candidate: '/api/admin/candidate.json',
  MemberNG: '/api/admin/member/ng.json',
  MemberEnquete: '/api/admin/member/enquete.json',
  MemberComment: '/api/admin/member/comment.json',
  MemberResumeAsset: '/api/admin/member/resume',
  MemberResume: '/api/admin/member/resume.json',
  MemberArea: '/api/admin/member/area.json',
  MemberStatusRecruit: '/api/admin/member/status/recruit.json',
  MemberMail: '/api/admin/member/mail.json',
  MemberWorkation: '/api/admin/member/workation.json',
  MemberWorkationZip: '/api/admin/member/workation.zip',
  MemberStatusWorkflow: '/api/admin/member/status/workflow.json',
  MemberServant: '/api/admin/member/servant.json',
  MemberEmployeeNumber: '/api/admin/member/employee_number.json',
  MemberExportZip: '/api/admin/member/export.zip',
  MemberStatusSuccess: '/api/admin/member/status/success.json',
  MemberAttachment: '/api/admin/member/attachment.json',
}
