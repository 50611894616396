
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('storeArea', ['findTArea']),
    tArea() {
      return this.findTArea(this.id)
    },
  },
})
