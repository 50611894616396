/* Styles */
import '@/scss/style.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Validater */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './vee-validate'

/* Vue. Main component */
import App from './App.vue'
import ValidationInput from '@/components/Shared/ValidationInput.vue'
import ValidationField from '@/components/Shared/ValidationField.vue'
import Datepicker from '@/components/Shared/Datepicker.vue'
import AreaLabel from '@/components/Shared/AreaLabel.vue'

import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

dayjs.locale(ja)

/* Default title tag */
const defaultDocumentTitle = '採用管理システム'

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
  store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit('overlayToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultInputHasCounter: false,
  defaultUseHtml5Validation: false,
  defaultStatusIcon: false,
  defaultProgrammaticPromise: true,
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationInput', ValidationInput)
Vue.component('ValidationField', ValidationField)
Vue.component('Datepicker', Datepicker)
Vue.component('AreaLabel', AreaLabel)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
