import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

import { merge } from '@/util'
import { TMemberResume } from '@/types/typeMemberResume'

export interface ResumeState {
  tMemberResumes: TMemberResume[]
}

const module: StoreModule<ResumeState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      tMemberResumes: [],
    }
  },
  getters: {},
  mutations: {
    pushResumes(
      state,
      payload: { memberId: number; tMemberResumes: TMemberResume[] },
    ) {
      state.tMemberResumes = state.tMemberResumes
        .filter(row => row.memberId !== payload.memberId)
        .concat(payload.tMemberResumes)
    },
    mergeResume(state, tMemberResume: TMemberResume) {
      state.tMemberResumes = merge(state.tMemberResumes, tMemberResume)
    },
    removeResume(state, resumeId: number) {
      state.tMemberResumes = state.tMemberResumes.filter(
        row => row.id !== resumeId,
      )
    },
  },
  actions: {
    async forgeResumes({ commit }, memberId: number) {
      const tMemberResumes = await TMemberResume.fetch(memberId)
      commit('pushResumes', { memberId, tMemberResumes })
    },
    receiveResume({ commit }, payload) {
      const tMemberResume = new TMemberResume(payload)
      commit('mergeResume', tMemberResume)
    },
    removeResume({ commit }, resumeId) {
      commit('removeResume', Number(resumeId))
    },
  },
}
export default module
