import { API, EndPoints } from '@/api'
import { UserRole } from '@/types/typeEnum'

export class AuthForm {
  loginId: string = ''
  password: string = ''
  get params() {
    return {
      login_id: this.loginId,
      password: this.password,
    }
  }

  login() {
    return API.post(EndPoints.Auth, { auth: this.params }).then(
      r => new AuthUser(r.data.auth),
    )
  }
}
export class AuthUser {
  id: number
  loginId: string
  name: string
  role: UserRole
  areas: number[] | null
  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.loginId = data.login_id || ''
    this.name = data.name || ''
    this.role = UserRole.of(data.role)
    this.areas = data.areas
  }

  static load() {
    return API.get(EndPoints.Auth).then(r => new AuthUser(r.data.auth))
  }

  static logout() {
    return API.delete(EndPoints.Auth)
  }
}

export class UserPasswordForm {
  password: string = ''
  confirmation: string = ''
  constructor() {
    this.password = ''
    this.confirmation = ''
  }

  save() {
    return API.put(EndPoints.Auth, { password: this.password })
  }
}
