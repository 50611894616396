import { VID, ApiSocketUri } from '@/api'
import { StoreModule } from '@/types/typesStore'
import { RootState } from '.'

interface SocketData {
  vid: string
  action: string
  payload: any
}

export interface SocketState {
  webSocket: WebSocket | null
  wsRetryCount: number
}

const module: StoreModule<SocketState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      webSocket: null,
      wsRetryCount: 0,
    }
  },
  getters: {},
  mutations: {
    fill(state, payload: any) {
      Object.keys(payload).forEach(key => {
        // @ts-ignore
        state[key] = payload[key]
      })
    },
  },
  actions: {
    forgeStates({ state, commit, dispatch }) {
      if (!ApiSocketUri) {
        return
      }
      const webSocket = new WebSocket(ApiSocketUri)
      webSocket.onopen = () => {
        console.log('webSocket.onopen')
        commit('fill', { webSocket, wsRetryCount: 0 })
      }
      webSocket.onclose = () => {
        console.log('webSocket.onclose')
        const wsRetryCount = state.wsRetryCount + 1
        commit('fill', { webSocket: null, wsRetryCount })
        setTimeout(() => {
          dispatch('forgeStates')
        }, wsRetryCount ** 2 * 1000)
      }
      webSocket.onmessage = evt => {
        const jsonData: SocketData = JSON.parse(evt.data)
        if (VID === jsonData.vid) {
          console.log('self message')
          return
        }
        dispatch(jsonData.action, jsonData.payload, { root: true })
      }
    },
  },
}
export default module
