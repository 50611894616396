
import Vue, { PropType } from 'vue'
import { ApplicantTableFilter, TMember } from '@/types/typeMember'
import AreaLabel from '@/components/Shared/AreaLabel.vue'
import { Mixin } from '@/mixin'
import { mapMutations } from 'vuex'
import DepartmentTag from '@/components/Shared/DepartmentTag.vue'
export default Vue.extend({
  components: { AreaLabel, DepartmentTag },
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    isLoading: Boolean,
    tMembers: Array as PropType<TMember[]>,
    applicantTableFilter: ApplicantTableFilter,
  },
  computed: {
    paginated(): boolean {
      return this.rows.length > this.perPage
    },
    checkedRows: {
      get(): TMember[] {
        return this.tMembers.filter(row =>
          this.applicantTableFilter.checkedIds.includes(row.id),
        )
      },
      set(tMembers: TMember[]) {
        this.applicantTableFilter.checkedIds = tMembers.map(row => row.id)
      },
    },
    rows(): TMember[] {
      return this.tMembers.filter(row => this.applicantTableFilter.test(row))
    },
  },
  methods: {
    ...Mixin.methods,
    ...mapMutations('storeMember', ['mergeMember']),
    willToDetail(tMember: TMember) {
      this.$router.push({
        name: 'ApplicantView',
        params: { code: tMember.code },
      })
    },
    willEditComment(tMember: TMember) {
      this.showPrompt('一覧コメント', tMember.note).then((value: string) => {
        tMember
          .updateNote(value)
          .then(result => {
            this.mergeMember(result)
          })
          .catch(this.handleError)
      })
    },
  },
})
