import { API, EndPoints } from '@/api'
import { assign } from '@/util'
import {
  AreaMaxHour,
  AreaPaymentLimit,
  AreaShiftType,
  WorkationRestType,
} from './typeEnum'

export class TArea {
  id: number
  name: string
  shortName: string | null
  isDirect: boolean
  maxHour: AreaMaxHour
  holidayCount: number
  paymentLimit: AreaPaymentLimit
  shiftType: AreaShiftType
  accountCode: string
  bankCode: string
  note: string
  no: number | null
  annualPaidLeaveNotes: string[]
  retireNotes: string[]
  workationNotes: string[]
  paymentCode: string
  paymentCodeMonth: string
  paymentCodeOther: string
  restType: WorkationRestType
  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.name = data.name || ''
    this.shortName = data.short_name || null
    this.isDirect = !!data.is_direct
    this.maxHour = AreaMaxHour.of(data.max_hour)
    this.holidayCount = Number(data.holiday_count)
    this.paymentLimit = AreaPaymentLimit.of(Number(data.payment_limit))
    this.shiftType = AreaShiftType.of(Number(data.shift_type))
    this.accountCode = data.account_code || ''
    this.bankCode = data.bank_code || ''
    this.note = data.note || ''
    this.no = data.no !== null ? Number(data.no) : null
    this.annualPaidLeaveNotes = data.annual_paid_leave_notes || []
    this.retireNotes = data.retire_notes || []
    this.workationNotes = data.workation_notes || []
    this.paymentCode = data.payment_code || ''
    this.paymentCodeMonth = data.payment_code_month || ''
    this.paymentCodeOther = data.payment_code_other || ''
    this.restType = WorkationRestType.of(data.rest_type)
  }

  static async fetch() {
    return API.get(EndPoints.CommonArea).then(r =>
      (r.data.areas as any[]).map(d => new TArea(d)),
    )
  }

  get someName() {
    return this.shortName || this.name
  }
}

export class AreaBasicForm {
  id: number = 0
  name: string = ''
  shortName: string = ''
  isDirect: boolean = false
  accountCode: string = ''
  bankCode: string = ''
  note: string = ''
  paymentCode: string = ''
  paymentCodeMonth: string = ''
  paymentCodeOther: string = ''

  fill(tArea: TArea) {
    assign(this, tArea)
  }

  get params() {
    return {
      id: this.id,
      name: this.name,
      short_name: this.shortName === '' ? null : this.shortName,
      is_direct: this.isDirect ? 1 : 0,
      account_code: this.accountCode,
      bank_code: this.bankCode,
      note: this.note || '',
      payment_code: this.paymentCode || '',
      payment_code_month: this.paymentCodeMonth || '',
      payment_code_other: this.paymentCodeOther || '',
    }
  }

  async create() {
    return API.post(EndPoints.Area, { area: this.params }).then(
      r => new TArea(r.data.area),
    )
  }
  async update() {
    return API.put(EndPoints.Area, { area: this.params }).then(
      r => new TArea(r.data.area),
    )
  }
}

export class AreaWorkationForm {
  id: number = 0
  maxHour: AreaMaxHour = AreaMaxHour.SevenHalf
  holidayCount: string = ''
  paymentLimit: AreaPaymentLimit = AreaPaymentLimit.MonthEnd
  shiftType: AreaShiftType = AreaShiftType.Monthly
  restType: WorkationRestType = WorkationRestType.OneHour
  annualPaidLeaveNote1 = ''
  annualPaidLeaveNote2 = ''
  retireNote1 = ''
  retireNote2 = ''
  retireNote3 = ''
  retireNote4 = ''
  workationNote1 = ''
  workationNote2 = ''
  workationNote3 = ''
  workationNote4 = ''
  workationNote5 = ''
  workationNote6 = ''
  workationNote7 = ''

  fill(tArea: TArea) {
    assign(this, tArea)
    this.holidayCount = tArea.holidayCount.toString() || ''
    if (tArea.annualPaidLeaveNotes.length >= 2) {
      this.annualPaidLeaveNote1 = tArea.annualPaidLeaveNotes[0]
      this.annualPaidLeaveNote2 = tArea.annualPaidLeaveNotes[1]
    }
    if (tArea.retireNotes.length >= 4) {
      this.retireNote1 = tArea.retireNotes[0]
      this.retireNote2 = tArea.retireNotes[1]
      this.retireNote3 = tArea.retireNotes[2]
      this.retireNote4 = tArea.retireNotes[3]
    }
    if (tArea.workationNotes.length >= 7) {
      this.workationNote1 = tArea.workationNotes[0]
      this.workationNote2 = tArea.workationNotes[1]
      this.workationNote3 = tArea.workationNotes[2]
      this.workationNote4 = tArea.workationNotes[3]
      this.workationNote5 = tArea.workationNotes[4]
      this.workationNote6 = tArea.workationNotes[5]
      this.workationNote7 = tArea.workationNotes[6]
    }
  }

  get params() {
    return {
      id: this.id,
      max_hour: this.maxHour.value,
      holiday_count:
        this.holidayCount === '' ? null : Number(this.holidayCount) || 0,
      payment_limit: this.paymentLimit.value,
      shift_type: this.shiftType.value,
      rest_type: this.restType.value,
      annual_paid_leave_notes: [
        this.annualPaidLeaveNote1,
        this.annualPaidLeaveNote2,
      ],
      retire_notes: [
        this.retireNote1,
        this.retireNote2,
        this.retireNote3,
        this.retireNote4,
      ],
      workation_notes: [
        this.workationNote1,
        this.workationNote2,
        this.workationNote3,
        this.workationNote4,
        this.workationNote5,
        this.workationNote6,
        this.workationNote7,
      ],
    }
  }

  async save() {
    return API.put(EndPoints.Area, { area: this.params }).then(
      r => new TArea(r.data.area),
    )
  }
}

export class AreaTableFilter {
  text: string = ''

  test(tArea: TArea): boolean {
    const search = `${tArea.name}${tArea.note}`
    if (this.text && !search.includes(this.text)) {
      return false
    }
    return true
  }
}
