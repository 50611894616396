export const DatetimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const DateFormat = 'YYYYMMDD'
export const TimeFormat = 'HHmm'
export const MonthKeyFormat = 'YYYYMM'
export const WEEKS: { [key: number]: string } = {
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
  7: '日',
}
export const WORK_HOURS = Array.from(Array(24))
  .map((_, i) => i + 1)
  .filter(h => h >= 6)

export const OFFICIAL_HOLIDAYS = [
  [
    [8, 13],
    [8, 14],
    [8, 15],
    [12, 30],
    [12, 31],
  ],
  [
    [1, 1],
    [1, 2],
    [1, 3],
  ],
]
