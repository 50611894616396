<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main"
    class="navbar"
    :class="navBarClass"
  >
    <div class="navbar-brand no-negative-margin-left">
      <a
        @click.prevent="menuToggle"
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only"
      >
        <b-icon :icon="menuToggleIcon" />
      </a>
    </div>
    <div v-if="isLayoutMobile" class="navbar-brand is-right">
      <span class="navbar-item">
        <b-icon icon="tag" custom-size="default" />
        <span v-if="authUser">{{ authUser.name }}</span>
      </span>
      <a
        class="navbar-item navbar-item-menu-toggle"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{
        'is-active': isMenuNavBarActive,
        'no-negative-margin-right': isLayoutBoxed,
      }"
    >
      <div class="navbar-end">
        <NavBarMenu class="has-divider" v-if="authUser">
          <b-icon icon="account" custom-size="default" />
          <span>{{ authUser.name }}</span>
          <div
            slot="dropdown"
            class="navbar-dropdown is-right"
            style=" max-height: 80vh; overflow: scroll;"
          >
            <span class="navbar-item">
              <b-icon icon="account" custom-size="default" />
              <span>{{ authUser.loginId }}</span>
            </span>
            <a class="navbar-item" @click="willChangePassword">
              <b-icon icon="key" custom-size="default" />
              <span>パスワードを変更する</span>
            </a>
            <span class="navbar-item">
              <b-icon icon="tie" custom-size="default" />
              <span>{{ authUser.role.label }}権限</span>
            </span>
            <hr class="navbar-divider" />
            <span class="navbar-item">
              以下の施設の応募者・採用候補者を管理できます。
            </span>
            <span
              class="navbar-item"
              v-for="tArea in authAreas"
              :key="tArea.id"
            >
              <b-icon icon="bookmark-outline" custom-size="default" />
              <span>{{ tArea.name }}</span>
            </span>
          </div>
        </NavBarMenu>
        <a
          class="navbar-item is-desktop-icon-only"
          title="ログアウト"
          @click="willLogout"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>ログアウト</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PasswordFormVue from '@/components/App/PasswordForm.vue'
import NavBarMenu from './NavBarMenu.vue'

export default {
  components: { NavBarMenu },
  data() {
    return {
      isMenuNavBarActive: false,
    }
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleIcon() {
      if (this.isLayoutAsideHidden) {
        return this.isAsideVisible ? 'close' : 'menu'
      }

      const isExpanded = this.isLayoutMobile
        ? this.isAsideMobileExpanded
        : this.isAsideExpanded

      return isExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip() {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    navBarClass() {
      let classAddon = ''

      if (!this.isLayoutBoxed) {
        classAddon += 'is-fixed-top '
      }

      if (this.navBarColor) {
        classAddon += this.navBarColor + ' '
      }

      return classAddon
    },
    ...mapState([
      'isLayoutMobile',
      'isLayoutAsideHidden',
      'isLayoutBoxed',
      'isNavBarVisible',
      'navBarColor',
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'isAsideRightActive',
      'hasUpdates',
    ]),
    ...mapState('storeAuth', ['authUser']),
    ...mapGetters('storeAuth', ['authAreas']),
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
  },
  methods: {
    ...mapActions('storeAuth', ['logout']),
    menuToggle() {
      if (this.isLayoutMobile) {
        this.$store.commit('asideMobileStateToggle')
      } else if (this.isLayoutAsideHidden) {
        this.$store.dispatch('asideVisibilityToggle')
      } else {
        this.$store.commit('asideStateToggle')
      }
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    updatesToggle() {
      this.$store.dispatch('asideRightToggle')
    },
    willLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'Login' })
        this.$buefy.snackbar.open({
          message: 'ログアウトしました',
          queue: false,
          type: 'is-danger',
        })
      })
    },
    willChangePassword() {
      console.log('willChangePassword')
      this.$buefy.modal.open({
        parent: this,
        component: PasswordFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
      })
    },
    clearChache() {
      // window.navigator.serviceWorker.getRegistrations()
      //   .then(registrations => {
      //     for (const registration of registrations) {
      //       registration.unregister()
      //     }
      //   })
      window.location.reload(true)
    },
  },
}
</script>
