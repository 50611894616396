import { API, EndPoints } from '@/api'
import dayjs, { Dayjs } from 'dayjs'
import { TMember } from './typeMember'

export class TMemberComment {
  id: number
  memberId: number
  areaId: number
  name: string
  text: string
  createdAt: Dayjs

  constructor(data: any) {
    this.id = Number(data.id)
    this.memberId = Number(data.member_id)
    this.areaId = Number(data.area_id)
    this.name = data.name
    this.text = data.text
    this.createdAt = dayjs(data.created_at)
  }

  static async fetch(memberId: number) {
    const params = { member_id: memberId }
    return API.get(EndPoints.MemberComment, { params }).then(r =>
      (r.data.comments as any[]).map(d => new TMemberComment(d)),
    )
  }

  static create(memberId: number, name: string, text: string) {
    const comment = { member_id: memberId, name, text }
    return API.post(EndPoints.MemberComment, { comment }).then(r => {
      return {
        tMemberComment: new TMemberComment(r.data.comment),
        tMember: new TMember(r.data.member),
      }
    })
  }

  update(text: string) {
    const comment = { id: this.id, text }
    return API.put(EndPoints.MemberComment, { comment }).then(
      r => new TMemberComment(r.data.comment),
    )
  }

  delete() {
    const params = { comment_id: this.id }
    return API.delete(EndPoints.MemberComment, { params })
  }
}
